import React, {useEffect, useState} from "react";
import styles from "../Photos/Photos.module.css";

function Photos() {
    const [images, setImages] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    useEffect(() => {
        // Dynamically import all images from the "images" folder
        const importAll = (r) => r.keys().map(r);
        const images = importAll(require.context('../../images/photos', false, /\.(png|jpe?g|svg)$/));
        setImages(images);
    }, []);

    const showPreviousImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const showNextImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function keyDownEvent(e) {
        if (e.key === "Escape") {
            e.stopPropagation();
            setSelectedImageIndex(null)
        }
        if (e.key === "ArrowLeft") {
            showPreviousImage()
        }
        if (e.key === "ArrowRight") {
            showNextImage()
        }
    }

    useEffect(() => {
        if (selectedImageIndex !== null) {
            window.addEventListener("keydown", keyDownEvent);
            return () => {
                window.removeEventListener("keydown", keyDownEvent);
            };
        }
    }, [keyDownEvent, selectedImageIndex]);

    return (
        <div className={styles.photoPage}>
            <h1>Album openingsreceptie</h1>
            <div className={styles.pictures}>
                {images.map((src, index) => (
                    // eslint-disable-next-line
                    <img
                        key={index}
                        src={src}
                        alt={`Image ${index + 1}`}
                        className={styles.picture}
                        onClick={() => setSelectedImageIndex(index)} // Set image in modal on click
                    />
                ))}
            </div>

            {/* Modal for full-size image */}
            {selectedImageIndex !== null && (
                <div className={styles.modal} onClick={() => setSelectedImageIndex(null)}>
                    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                        <img
                            src={images[selectedImageIndex]}
                            alt={`Full Size ${selectedImageIndex + 1}`}
                            className={styles.fullSizeImage}
                        />

                        <button
                            className={styles.closeButton}
                            onClick={() => setSelectedImageIndex(null)}
                        >
                            X
                        </button>

                        <button
                            className={styles.leftButton}
                            onClick={(showPreviousImage)}
                        >
                            &lt;
                        </button>

                        <button
                            className={styles.rightButton}
                            onClick={(showNextImage)}
                        >
                            &gt;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Photos;