import React, {useEffect, useState} from 'react'
import styles from './Home.module.css'
import LinkItem from "./LinkItem";

function Home() {

    const [items, setItems] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.PUBLIC_URL}/Data/data.json`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch data: ${response.statusText}`);
                }
                const data = await response.json();
                setItems(data); // Assuming the data is an array of objects
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.welcome}>
                <h1 className={styles.title}>Welkom bij Volle Petrol</h1>
                <div className={styles.links}>
                    {Array.isArray(items) && items.map((item, index) => (
                        item.show && <LinkItem key={index} item={item}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Home
