import styles from "./Home.module.css";
import React from "react";

function LinkItem({item}) {

    const imageSrc = require(`../../images/${item.imagePath}`);

    return (
        <div>
            <a href={item.link}
               target="_blank" rel="noreferrer">
                <div className={styles.link}>
                    <img className={styles.icon} src={imageSrc} alt="icon"/>
                    <div className={styles.iconTextContainer}>
                        <p className={styles.iconText}>{item.title}</p>
                        <p className={styles.description}>{item.description}</p>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default LinkItem;