import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Photos from "./components/Photos/Photos";
import Footer from "./components/Footer/Footer";

function App() {
    return (
        <div className="App">
            <Router>
                <Header/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/photos" element={<Photos/>}/>
                </Routes>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;
